import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import {
	Button,
	Form,
	Modal,
	ModalBody,
	ModalHeader,
	Spinner,
} from 'reactstrap';
import OutlineInput from '../../../shared/FormField/OutlineInput';
import Select from 'react-select';
import useReduxHandler from '../../../../hooks/useReduxHandler';
import md5 from 'md5';
import SelectInput from '../../../shared/FormField/SelectInput';
import NotificationWidget from '../../../shared/NotificationWidget';
import CheckBoxInput from '../../../shared/FormField/CheckBoxInput';

import styled from 'styled-components';

export const Label = styled.label`
	font-size: 0.7rem;
	font-weight: 500;
	color: #909090d9;
	padding: 5px 5px 0px 5px;
	background: #fff;
	margin: 0;
	z-index: 9999;
	position: relative;
	top: 10px;
	left: 10px;
`;

const ModalFormUser = ({
	isOpenCreateOrEditUser,
	toggleOpenCreateOrEdit,
	modalConfig,
	setReloadUsers,
	setItems,
}) => {
	console.log('🚀 ~ file: ModalFormUser.js:42 ~ modalConfig', modalConfig);
	const { Http } = useReduxHandler();
	const { register, errors, handleSubmit, reset, watch, setValue, control } =
		useForm();

	const [isLoading, setIsLoading] = useState(false);
	const [rolesOptions, setRolesOptions] = useState([]);
	const [rolesSelected, setRolesSelected] = useState([]);

	useEffect(() => {
		Http(
			'get',
			'https://gateway.billshark.com/apex-user/options/roles',
			{},
			{},
			(response) => {
				const tOptions = [];
				const tSelected = [];
				Object.keys(response.data.options).forEach((el) => {
					tOptions.push({ label: response.data.options[el].val, value: el });
					const tExistRol = modalConfig?.roles?.find((rol) => rol === el);
					if (tExistRol) {
						tSelected.push({ label: response.data.options[el].val, value: el });
					}
				});
				setRolesOptions(tOptions);
				setRolesSelected(tSelected);
			},
			(err) => {
				console.log('🚀 ~ file: index.js ~ line 25 ~ didMount ~ err', err);
				NotificationWidget.getErrorNotification({ message: err.message });
			}
		);
	}, []);

	const getPassword = (newPassword) => {
		if (!modalConfig?.id) {
			return md5(newPassword);
		}
		if (modalConfig?.id) {
			if (modalConfig?.password !== newPassword) {
				return md5(newPassword);
			}
		}
		return newPassword;
	};

	const onSubmitHandler = (data) => {
		console.log(
			'🚀 ~ file: ModalFormUser.js ~ line 61 ~ onSubmitHandler ~ data',
			data
		);
		if (!Boolean(data?.username)) {
			NotificationWidget.getErrorNotification({
				message: 'The username field is empty',
			});
			return;
		}
		if (!Boolean(data?.password)) {
			NotificationWidget.getErrorNotification({
				message: 'The password field is empty',
			});
			return;
		}
		if (rolesSelected.length === 0) {
			NotificationWidget.getErrorNotification({
				message: 'You must choose at least one rol',
			});
			return;
		}

		setIsLoading(true);
		const method = modalConfig?.id ? 'patch' : 'post';
		let url = 'https://gateway.billshark.com/apex-user/users';
		if (modalConfig?.id) {
			url = `https://gateway.billshark.com/apex-user/users/${modalConfig?.id}`;
		}
		Http(
			method,
			url,
			{
				...data,
				roles: rolesSelected?.map((el) => el.value),
				password: getPassword(data.password),
			},
			{},
			(response) => {
				console.log(
					'🚀 ~ file: index.js ~ line 18 ~ didMount ~ response',
					response
				);
				// setReloadUsers(true);
				toggleOpenCreateOrEdit();
				// setItems(response.data.users);
				setIsLoading(false);
				if (!modalConfig?.id) {
					setItems((prev) => [{ ...response.data.user }, ...prev]);
				}
				// setReloadUsers(true);
			},
			(err) => {
				console.log('🚀 ~ file: index.js ~ line 25 ~ didMount ~ err', err);
				NotificationWidget.getErrorNotification({ message: err.message });
				setIsLoading(false);
			}
		);

		reset(data);
	};

	const customControlStyles = (base) => ({
		...base,
		width: '100%',
		fontSize: '12px',
		menuPortal: { ...base, zIndex: 9999 },
	});

	return (
		<Modal
			isOpen={isOpenCreateOrEditUser}
			toggle={toggleOpenCreateOrEdit}
			size="md"
			className="mt-5"
		>
			<ModalHeader
				toggle={toggleOpenCreateOrEdit}
				className="modal-custom-header font-weight-bold"
			>
				{modalConfig?.id
					? `Update ${modalConfig?.username} teammate`
					: 'Create new teammate'}
			</ModalHeader>
			<ModalBody>
				<Form onSubmit={handleSubmit(onSubmitHandler)}>
					<div className="row">
						<div className="col-md-12 mb-4">
							<OutlineInput
								name="username"
								label="User Name"
								required
								register={register}
								errors={errors}
								watch={watch}
								setValue={setValue}
								control={control}
								defaultValues={{ username: modalConfig?.username }}
								style={{ height: '50px' }}
								readOnly={Boolean(modalConfig?.id)}
							/>
						</div>
						<div className="col-md-12">
							<OutlineInput
								name="password"
								label="Password"
								required
								register={register}
								errors={errors}
								watch={watch}
								setValue={setValue}
								control={control}
								defaultValues={{ password: modalConfig?.password }}
								dataType="password"
								style={{ height: '50px' }}
							/>
						</div>
						<div className="col-md-12 mb-4">
							<Label htmlFor={`role`}>Role</Label>
							<Select
								label="Role"
								name="roles"
								value={rolesSelected}
								options={rolesOptions}
								styles={{ control: customControlStyles }}
								isMulti
								formatOptionLabel={(option) => <span>{option.label}</span>}
								onChange={(data) => {
									if (data?.length > 0) {
										setRolesSelected(data);
									} else {
										setRolesSelected([]);
									}
								}}
								// menuPortalTarget={document.body}
								menuPosition="fixed"
								portalPlacement="bottom"
								placeholder="Select role..."
								required
								register={register}
								errors={errors}
								watch={watch}
								control={control}
								isDisabled={isLoading || !rolesOptions}
							></Select>
						</div>
						{modalConfig?.id && (
							<>
								<div className="col-md-12 mb-4">
									<CheckBoxInput
										// type="checkbox"
										name="active"
										register={register}
										errors={errors}
										watch={watch}
										setValue={setValue}
										control={control}
										defaultValues={{ active: modalConfig?.active }}
										label="Active"
									/>
								</div>
							</>
						)}
					</div>
					<div className="row justify-content-md-center mt-2 ">
						<div className="col-md-8">
							<Button color="dark" block type="submit">
								{isLoading ? (
									<Spinner />
								) : modalConfig?.id ? (
									'Update teammate'
								) : (
									'Create teammate'
								)}
							</Button>
						</div>
					</div>
				</Form>
			</ModalBody>
		</Modal>
	);
};

ModalFormUser.propTypes = {
	isOpenCreateOrEditUser: PropTypes.bool,
	toggleOpenCreateOrEdit: PropTypes.func.isRequired,
};

export default ModalFormUser;
