/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FaArrowUp, FaArrowDown } from 'react-icons/fa';
// import { navigate } from 'gatsby';

const Button = styled.button`
	font-size: 45px;
	position: fixed;
	right: 0;
	bottom: 130px;
	margin: 0 60px 60px 0;
	border-radius: 50%;
	border: none;
	opacity: 0;
	visibility: hidden;
	transition: 0.4s opacity ease;
	cursor: pointer;
	outline: none;
	background: transparent;
`;
const ButtonDown = styled.a`
	font-size: 45px;
	position: fixed;
	right: 0;
	bottom: 90px;
	margin: 0 60px 60px 0;
	border-radius: 50%;
	border: none;
	opacity: 1;
	visibility: visible;
	transition: 0.4s opacity ease;
	cursor: pointer;
	outline: none;
	background: transparent;
`;
function scrollToY(scrollTargetYY, speedd, easingg) {
	const scrollY =
		(typeof window !== 'undefined' && window.scrollY) ||
		document.documentElement.scrollTop;
	const scrollTargetY = scrollTargetYY || 0;
	const speed = speedd || 2000;
	const easing = easingg || 'easeOutSine';
	let currentTime = 0;

	const time = Math.max(
		0.1,
		Math.min(Math.abs(scrollY - scrollTargetY) / speed, 0.8)
	);

	const easingEquations = {
		easeOutSine: (pos) => Math.sin(pos * (Math.PI / 2)),
		easeInOutSine: (pos) => -0.5 * (Math.cos(Math.PI * pos) - 1),
		easeInOutQuint: (pos) => {
			let newPos = pos;
			newPos /= 0.5;
			if (newPos < 1) {
				return 0.5 * newPos ** 5;
			}
			return 0.5 * (newPos - 2) ** 5 + 2;
		},
	};

	function tick() {
		if (typeof window !== 'undefined') {
			currentTime += 1 / 60;

			const p = currentTime / time;
			const t = easingEquations[easing](p);

			if (p < 1) {
				window.requestAnimFrame(tick);

				window.scrollTo(0, scrollY + (scrollTargetY - scrollY) * t);
			} else {
				window.scrollTo(0, scrollTargetY);
			}
		}
	}
	tick();
}

const BackToTop = ({
	showAt,
	showOnScrollUp,
	onClick,
	scrollTo,
	speed,
	easing,
	style,
	children,
	showBackToBottom,
}) => {
	const [isAtRange, setIsAtRange] = useState(false);

	useEffect(() => {
		if (typeof window !== 'undefined') {
			window.requestAnimFrame = (() => {
				const clb = (callback) => {
					window.setTimeout(callback, 1000 / 60);
				};
				return (
					window.requestAnimationFrame ||
					window.webkitRequestAnimationFrame ||
					window.mozRequestAnimationFrame ||
					clb
				);
			})();
			let lastScrollTop = 0;
			const hendleListener = () => {
				const st = window.pageYOffset || document.documentElement.scrollTop;
				const backToTop = document.querySelector('.back-to-top');
				if (st > showAt) {
					setIsAtRange(true);
				} else {
					setIsAtRange(false);
				}
				if (backToTop) {
					if (showOnScrollUp) {
						if (st > lastScrollTop) {
							backToTop.style.opacity = 0;
							backToTop.style.visibility = 'hidden';
						} else if (isAtRange) {
							backToTop.style.opacity = 1;
							backToTop.style.visibility = 'visible';
						} else {
							backToTop.style.opacity = 0;
							backToTop.style.visibility = 'hidden';
						}
					} else if (isAtRange) {
						backToTop.style.opacity = 1;
						backToTop.style.visibility = 'visible';
					} else {
						backToTop.style.opacity = 0;
						backToTop.style.visibility = 'hidden';
					}
				}
				lastScrollTop = st <= 0 ? 0 : st;
			};
			window.addEventListener('scroll', hendleListener);
			return () => window.removeEventListener('scroll', hendleListener);
		}
		return () => {};
	});

	const moveToTop = () => {
		if (onClick) {
			onClick();
		}
		scrollToY(scrollTo, speed, easing);
	};

	return (
		<>
			<Button className="back-to-top" onClick={moveToTop} style={style || {}}>
				{children || <FaArrowUp />}
			</Button>
			{showBackToBottom && (
				<ButtonDown
					className="go-to-footer"
					href="#footer"
					// onClick={() => navigate('#footer')}
					style={style || {}}
				>
					<FaArrowDown />
				</ButtonDown>
			)}
		</>
	);
};

BackToTop.propTypes = {
	showAt: PropTypes.number,
	showOnScrollUp: PropTypes.bool,
	onClick: PropTypes.func,
	scrollTo: PropTypes.number,
	speed: PropTypes.number,
	easing: PropTypes.string,
	style: PropTypes.objectOf(PropTypes.any),
};

BackToTop.defaultProps = {
	showAt: 1000,
	showOnScrollUp: false,
	onClick: () => {},
	scrollTo: 0,
	speed: 1500,
	easing: 'easeOutSine',
	style: {},
};

export default BackToTop;
