import React, { useEffect, useState } from 'react';
import {
	Button,
	Form,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Spinner,
} from 'reactstrap';
import { Check, Pencil, Plus, PlusCircle, Trash } from 'styled-icons/bootstrap';
import { RadioUnchecked } from 'styled-icons/icomoon';
import useReduxHandler from '../../../../hooks/useReduxHandler';
import OutlineInput from '../../../shared/FormField/OutlineInput';
import NotificationWidget from '../../../shared/NotificationWidget';
import PaginatorWidget from '../../../shared/Paginator/PaginatorWidget';
import ModalFormUser from './ModalFormUser';
import styled from 'styled-components';

const PageTitle = styled.h1`
	font-family: Jost;
	font-style: normal;
	font-weight: 700;
	font-size: 48px;
	line-height: 60px;
`;

const Users = (props) => {
	const [isLoading, setIsLoading] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);
	const [items, setItems] = useState([]);
	const [reloadUsers, setReloadUsers] = useState(true);
	const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
	const [isOpenCreateOrEditUser, setIsOpenCreateOrEditUser] = useState(false);
	const [modalConfig, setModalConfig] = useState(null);
	const [pagination, setPagination] = useState({
		itemsPerPage: 15,
		totalItems: 0,
		totalPages: 0,
		currentPage: 1,
	});
	const toggleOpenCreateOrEdit = () => {
		setIsOpenCreateOrEditUser(!isOpenCreateOrEditUser);
	};
	const toggleOpenDeleteModal = () => {
		setIsOpenDeleteModal(!isOpenDeleteModal);
	};
	const { Http } = useReduxHandler();

	const fetchItems = (currentPage) => {
		setIsLoading(true);
		Http(
			'get',
			`https://gateway.billshark.com/apex-user/users?page_index=${currentPage}&page_size=${pagination.itemsPerPage}`,
			{},
			{},
			(response) => {
				console.log(
					'🚀 ~ file: index.js ~ line 18 ~ didMount ~ response',
					response
				);
				setItems(response.data.users);
				setPagination((prev) => ({
					...prev,
					totalItems: response.data.total_items,
					totalPages: response.data.total_pages,
					currentPage: response.data.page_index,
				}));
				setIsLoading(false);
			},
			(err) => {
				console.log('🚀 ~ file: index.js ~ line 25 ~ didMount ~ err', err);
				NotificationWidget.getErrorNotification({ message: err.message });
				setIsLoading(false);
			}
		);
	};

	useEffect(() => {
		if (reloadUsers) {
			setReloadUsers(false);
			fetchItems(pagination.currentPage);
		}
	}, [reloadUsers]);

	const handleDeleteUser = async () => {
		setIsDeleting(true);
		Http(
			'delete',
			`https://gateway.billshark.com/apex-user/users/${modalConfig.id}`,
			{},
			{},
			(response) => {
				console.log(
					'🚀 ~ file: index.js ~ line 18 ~ didMount ~ response',
					response
				);
				setIsDeleting(false);
				// setReloadUsers(true);
				setIsOpenDeleteModal(false);
				// setReloadUsers(true);
				setItems((prev) =>
					prev.filter((el) => el.id !== response?.data?.user?.id)
				);
			},
			(err) => {
				console.log('🚀 ~ file: index.js ~ line 25 ~ didMount ~ err', err);
				NotificationWidget.getErrorNotification({ message: err.message });
				setIsDeleting(false);
			}
		);
	};
	const onPageChanged = (page) => {
		fetchItems(page);
	};
	return (
		<div className="teammates-page container">
			<div className="row">
				<div className="col-md-12">
					<div
						className="d-flex justify-content-between"
						style={{
							marginTop: '2rem',
						}}
					>
						<PageTitle>Teammates</PageTitle>
						<button
							className="btn btn-primary btn-icons"
							onClick={() => {
								setModalConfig(null);
								setIsOpenCreateOrEditUser(true);
							}}
						>
							<PlusCircle size="24" /> Add teammate
						</button>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-md-12">
					{isLoading ? (
						<div className="text-center mt-5">
							<Spinner animation="border" role="status">
								<span className="visually-hidden">Loading users...</span>
							</Spinner>
						</div>
					) : (
						<>
							<table className="table">
								<thead>
									<tr>
										<th className="align-middle p-2">Username</th>
										<th className="align-middle p-2">Active</th>
										<th className="align-middle p-2">Partner user</th>
										<th className="align-middle p-2">Actions</th>
									</tr>
								</thead>
								<tbody>
									{items?.map((el) => {
										return (
											<tr key={el.id}>
												<td className="align-middle p-2">{el.username}</td>
												<td className="align-middle p-2">
													{el.active ? (
														<Check
															size="16"
															style={{
																color: 'green',
															}}
														/>
													) : (
														<RadioUnchecked
															size="16"
															style={{
																color: 'red',
															}}
														/>
													)}
												</td>
												<td className="align-middle p-2">
													{el?.user_partner?.name}
												</td>
												<td className="align-middle p-2">
													<Button
														className="p-1 btn btn-light"
														color="transparent"
														onClick={() => {
															setModalConfig(el);
															setIsOpenCreateOrEditUser(true);
														}}
														title="Edit"
													>
														<Pencil size="20" />
													</Button>
													<Button
														className="p-1 btn btn btn-light ml-1"
														color="transparent"
														onClick={() => {
															setModalConfig(el);
															setIsOpenDeleteModal(true);
														}}
														title="Delete"
													>
														<Trash size="20" />
													</Button>
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
							<div className="py-5 d-flex justify-content-center align-content-center">
								<PaginatorWidget
									totalPages={pagination.totalPages}
									current={pagination.currentPage}
									onPageChanged={onPageChanged}
								/>
							</div>
						</>
					)}
					{isOpenCreateOrEditUser && (
						<ModalFormUser
							isOpenCreateOrEditUser={isOpenCreateOrEditUser}
							toggleOpenCreateOrEdit={toggleOpenCreateOrEdit}
							modalConfig={modalConfig}
							setReloadUsers={setReloadUsers}
							setItems={setItems}
						/>
					)}
					{isOpenDeleteModal && (
						<Modal isOpen={isOpenDeleteModal} toggle={toggleOpenDeleteModal}>
							<ModalHeader
								toggle={toggleOpenDeleteModal}
								className="modal-custom-header"
							>
								Confirm delete teammate
							</ModalHeader>
							<ModalBody>
								<h6>
									Are you sure you want to remove teammate{' '}
									<i>{modalConfig.username}</i>?
								</h6>
							</ModalBody>
							<ModalFooter>
								<Button
									color="danger"
									size="md"
									onClick={() => {
										handleDeleteUser();
										// setIsOpenDeleteModal(false);
									}}
								>
									{isDeleting ? <Spinner size="14" /> : 'Yes'}
								</Button>
								<Button
									color="dark"
									size="md"
									onClick={() => setIsOpenDeleteModal(false)}
								>
									No
								</Button>
							</ModalFooter>
						</Modal>
					)}
				</div>
			</div>
		</div>
	);
};

Users.propTypes = {};

export default Users;
